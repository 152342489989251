import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UserService } from '@features/auth';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SapCustomerConfig, SAP_CUSTOMER_CONFIG } from '../config';
import { CustomerSelectors } from '../shared';
import { Industries, IndustriesDE, Market, Segment } from './industries';

@Component({
  selector: 'paldesk-industry-selection',
  templateUrl: './industry-selection.component.html',
  styleUrls: ['./industry-selection.component.scss'],
  standalone: false,
})
export class IndustrySelectionComponent implements OnInit, OnDestroy {
  @Input()
  isTheOnlyRow: boolean;
  @Input()
  addable: boolean;
  @Output()
  add: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  delete: EventEmitter<void> = new EventEmitter<void>();

  segments: Segment[] = [];
  markets: Market[] = [];

  industriesTranslated: { [key: string]: Market[] };

  segmentControl = new UntypedFormControl('');
  marketControl = new UntypedFormControl('');

  isIndustryOptional;

  private destroy$ = new Subject<void>();

  constructor(
    @Optional() @Inject(SAP_CUSTOMER_CONFIG) private config: SapCustomerConfig,
    private store: Store<any>,
    private userService: UserService,
  ) {
    this.isIndustryOptional = this.config?.edit_customer_industry_optional;

    this.segmentControl.valueChanges.subscribe((data) => {
      this.changeMarkets(data);
      if (this.isIndustryOptional) {
        this.marketControl.setValidators(data ? [Validators.required] : []);
        this.marketControl.updateValueAndValidity();
      }
    });
    this.industriesTranslated = this.getIndustriesTranslated();
    this.segments = Object.keys(this.industriesTranslated).map((item) => ({
      name: item,
      disabled: false,
    }));

    if (!this.isIndustryOptional) {
      this.segmentControl.setValidators(Validators.required);
      this.marketControl.setValidators(Validators.required);
    }
  }

  ngOnInit(): void {
    this.store
      .select(CustomerSelectors.selectedIndustries)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.disableMarkets(data);
        this.disableSegments(data);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setIndustry(value: string) {
    if (value !== undefined) {
      const segmentName = Object.keys(this.industriesTranslated).find(
        (industry) =>
          this.industriesTranslated[industry].some(
            (market) => market.id === value,
          ),
      );

      if (segmentName) {
        this.segmentControl.setValue(segmentName);
        this.marketControl.setValue(value);
        this.markets = this.industriesTranslated[segmentName];
      } else {
        this.segmentControl.setValue('');
        this.marketControl.setValue('');
        this.markets = [];
      }
    }
  }

  changeMarkets(segment: string) {
    this.markets = this.industriesTranslated[segment];
    this.marketControl.setValue('');
  }

  markAsTouched() {
    this.segmentControl.markAsTouched();
    this.marketControl.markAsTouched();
  }

  private disableMarkets(selectedIndustries: string[]): void {
    if (this.markets) {
      this.markets.forEach((market) => {
        market.disabled = selectedIndustries.includes(market.id);
      });
    }
  }

  private disableSegments(selectedIndustries: string[]): void {
    const industries = { ...this.industriesTranslated };
    for (const industry of Object.keys(industries)) {
      industries[industry].forEach((market) => {
        market.disabled = selectedIndustries.includes(market.id);
      });
    }

    this.segments = Object.keys(industries).map(
      (segmentName) =>
        ({
          name: segmentName,
          disabled: industries[segmentName].every(
            (market) => market.disabled || false,
          ),
        }) as Segment,
    );
  }

  private getIndustriesTranslated() {
    if (this.userService.userContext.lang === 'DE') {
      return IndustriesDE;
    }

    return Industries;
  }
}
