import { Pipe, PipeTransform } from '@angular/core';

/**
 * Removes leading Zeros from strings
 * Useful for SAP Data which comes with leading zeros
 */
@Pipe({
  name: 'removeLeadingZeros',
  standalone: false,
})
export class RemoveLeadingZerosPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value: any, _args?: any): any {
    if (value) {
      return value.replace(value.match('^0+(?!$)'), '');
    } else {
      return value;
    }
  }
}
