import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BusinessPartnerResponse } from '@data-access/bpm-generated';
import { Store } from '@ngrx/store';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomerActions, CustomerSelectors } from '../shared';
@Component({
  selector: 'paldesk-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CustomerDetailsComponent {
  @Input() isNewDesign = false;

  @Output() customerDetailsLoaded = new EventEmitter<BusinessPartnerResponse>();
  @Input()
  set businessPartnerNumber(value: string) {
    this.store.dispatch(
      CustomerActions.LoadCustomerByBusinessPartnerNumber({
        payload: value,
      }),
    );
    this.setCustomer(value);
  }
  @Input()
  set customerNumber(value: string) {
    this.store.dispatch(
      CustomerActions.LoadCustomerBySapId({
        payload: value,
      }),
    );
    this.setCustomer(value);
  }

  customer$: Observable<BusinessPartnerResponse | undefined>;

  constructor(private store: Store<any>) {}

  private setCustomer(bpmIdOrCustomerNumber: string) {
    this.customer$ = this.store
      .select(CustomerSelectors.byId(bpmIdOrCustomerNumber))
      .pipe(
        filterTruthy(),
        tap((bp) => {
          this.customerDetailsLoaded.emit(bp);
        }),
      );
  }
}
