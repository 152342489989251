import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PARTNERTYPES } from '@config';
import {
  BusinessPartnerResponse,
  CountryResponse,
} from '@data-access/bpm-generated';
import { UserService } from '@features/auth';
import { CountryFacade } from '@features/root-store';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, first, takeUntil } from 'rxjs/operators';
import { CustomerDetailsComponent } from '../customer-details/customer-details.component';
import { EditCustomerComponent } from '../edit-customer/edit-customer.component';
import { CustomerActions, CustomerFilter, CustomerSelectors } from '../shared';

@Component({
  selector: 'paldesk-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  standalone: false,
})
export class CustomersComponent implements OnInit, OnDestroy {
  @Input()
  partnerTypes: PARTNERTYPES[];
  @Input()
  excludeAccountingGroups: string[] = [];
  @Input()
  includeOnlyAccountingGroups: string[] = [];
  @Input()
  allowCreateCustomer = true;
  @Input()
  paIdentificationTypesToInclude: string[] = [];
  @Input()
  isNewDesign = false;
  @Input() disableSelectionForCompanyExistingInBPM = false;
  @Input() searchInBpmFirst = true;
  @Input() initialFilterValues?: CustomerFilter;
  @Input() selectedPartner = false;
  @Input() dialogTitle = '';
  @Input() hideCloseBtn = false;

  @Output()
  selected: EventEmitter<BusinessPartnerResponse> =
    new EventEmitter<BusinessPartnerResponse>();
  loading$: Observable<boolean>;
  searchError$: Observable<boolean>;
  updated$: Observable<boolean>;
  customers$: Observable<BusinessPartnerResponse[] | undefined>;
  customersCount$: Observable<number | undefined>;
  countries$: Observable<CountryResponse[] | undefined>;
  filter$: Observable<CustomerFilter>;
  currentCustomer: BusinessPartnerResponse;
  searchPerformed$: Observable<boolean>;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private store: Store<any>,
    private userService: UserService,
    private countryFacade: CountryFacade,
  ) {
    // we need only the first emit of filter, otherwise every filter change will emit values
    this.filter$ = this.store.select(CustomerSelectors.filter).pipe(first());
    this.loading$ = this.store.select(CustomerSelectors.loading);
    this.searchError$ = this.store.select(CustomerSelectors.searchError);
    this.updated$ = this.store.select(CustomerSelectors.updated);
    this.searchPerformed$ = this.store.select(
      CustomerSelectors.searchPerformed,
    );
    this.customers$ = this.store.select(CustomerSelectors.list);
    this.customersCount$ = this.store.select(CustomerSelectors.listCount);
    this.countries$ = this.countryFacade.getCountries();
  }

  ngOnInit() {
    this.store.dispatch(CustomerActions.ResetCustomerEdit());
    this.store.dispatch(CustomerActions.ResetSearchResult());
    const defaultFilter = {
      country: this.userService.userContext.country,
    } as CustomerFilter;

    this.store.dispatch(
      CustomerActions.ChangeFilter({
        payload: this.initialFilterValues || defaultFilter,
      }),
    );

    this.store
      .select(CustomerSelectors.current)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.currentCustomer = data;
        }
      });
    this.updated$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data && this.currentCustomer) {
        this.selected.emit(this.currentCustomer);
      }
    });
  }

  /**
   * fire actions when filter changes
   * @param filter
   */
  filterChange(filter: CustomerFilter) {
    this.store.dispatch(CustomerActions.ChangeFilter({ payload: filter }));
    const _searchInBpmFirst =
      this.excludeAccountingGroups.length ||
      this.includeOnlyAccountingGroups.length ||
      this.paIdentificationTypesToInclude.length
        ? false
        : this.searchInBpmFirst;

    this.store.dispatch(
      CustomerActions.LoadCustomerList({
        payload: {
          excludeAccountingGroups: this.excludeAccountingGroups,
          includeOnlyAccountingGroups: this.includeOnlyAccountingGroups,
          partnerTypes: this.partnerTypes,
          paIdentificationTypesToInclude: this.paIdentificationTypesToInclude,
          searchInBpmFirst: _searchInBpmFirst,
        },
      }),
    );
  }

  /**
   * edit customer
   * @param customerNumber
   */
  openEditCustomer(customerNumber: string) {
    this.store.dispatch(CustomerActions.ResetPossibleDuplicates());
    const config = new MatDialogConfig();
    config.data = {
      customerNumber: customerNumber,
    };
    config.width = '1000px';

    const editCustomerDialogRef = this.dialog.open(
      EditCustomerComponent,
      config,
    );

    editCustomerDialogRef.componentInstance.selectFromDuplicates.subscribe(
      (customer) => {
        this.selected.emit(customer);
        this.store.dispatch(CustomerActions.ResetSearchResult());
        editCustomerDialogRef.close();
      },
    );
  }

  /**
   * show customer
   * @param customer
   */
  openCustomerDetails(customer: BusinessPartnerResponse) {
    const customerDetailsDialog = this.dialog.open(CustomerDetailsComponent);
    if (customer.business_partner_number) {
      customerDetailsDialog.componentInstance.businessPartnerNumber =
        customer.business_partner_number;
    } else if (
      customer.partner_type === PARTNERTYPES.SUPPLIER &&
      customer.supplier_number
    ) {
      customerDetailsDialog.componentInstance.customerNumber =
        customer.supplier_number;
    } else {
      customerDetailsDialog.componentInstance.customerNumber =
        customer.customer_number;
    }

    customerDetailsDialog.componentInstance.isNewDesign = this.isNewDesign;
  }

  showTable(event: any) {
    this.selectedPartner = !event;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
