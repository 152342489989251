import { Component, OnInit, ViewContainerRef } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SAP_PARTNER_ROLES } from '@config';
import { BusinessPartnerResponse } from '@data-access/bpm-generated';
import { DsSpacingPipe } from '@design-system/cdk/spacing';
import { UserService } from '@features/auth';
import { CustomersComponent } from '@features/sap-customer';
import { Store } from '@ngrx/store';
import {
  OrderRequest,
  ShippingItem,
} from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { PalfingerValidators } from '@utils/palfinger-validators';
import { Observable, combineLatest, of } from 'rxjs';
import { map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { SparepartsShopState } from '../../../store';
import { CheckOutActions, CheckOutSelectors } from '../../../store/checkout';

@Component({
  selector: 'sp-shop-checkout-settings',
  templateUrl: './checkout-settings.component.html',
  styleUrls: ['./checkout-settings.component.scss'],
  standalone: false,
})
export class CheckOutSettingsComponent implements OnInit {
  readonly currentDate = new Date();
  deliveryDate;
  palf_germany_sap_user = '0000001104';
  checkOutForm: UntypedFormGroup;
  shippingConditions$: Observable<ShippingItem[] | undefined>;
  customerNumber$: Observable<string | undefined>;
  weeklyOrderVisible$ = of(false);
  isGermany$ = of(false);
  isNorthAmerica$ = of(false);

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<SparepartsShopState>,
    private userService: UserService,
    private readonly _viewContainerRef: ViewContainerRef,
    private _dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.checkOutForm = this.fb.group({
      deliveryDate: [
        this.currentDate,
        [Validators.required, PalfingerValidators.minDate(this.currentDate)],
      ],
      goodsRecipient: [undefined, Validators.required],
      orderNumber: ['', [Validators.required, Validators.maxLength(20)]],
      shippingConditions: [undefined, Validators.required],
      completeDelivery: false,
      weeklyOrder: false,
      comment: ['', Validators.maxLength(150)],
      orderType: [],
      shipFromWch: false,
      includesTmfItems: false,
      shipCollect: false,
      shipCollectAccountNumber: [''],
    });

    this.customerNumber$ = this.store
      .select(CheckOutSelectors.getShippingBusinessPartner)
      .pipe(
        withLatestFrom(this.userService.currentUser),
        map(([bp, user]) => bp?.customer_number || user?.company_sapid),
        tap((customerNumber) => {
          this.checkOutForm.controls['goodsRecipient'].setValue(customerNumber);
        }),
      );

    this.shippingConditions$ = this.store
      .select(CheckOutSelectors.getShippingConditions)
      .pipe(map((shipping) => shipping?.shipping_items));

    this.checkOutForm.controls['shipCollect'].valueChanges.subscribe(
      (shipCollectValue) => {
        if (shipCollectValue) {
          this.checkOutForm.controls['shipCollectAccountNumber'].setValidators([
            Validators.required,
            Validators.maxLength(20),
          ]);
        } else {
          this.checkOutForm.controls['shipCollectAccountNumber'].setValidators(
            null,
          );
        }
        this.checkOutForm.updateValueAndValidity();
      },
    );

    combineLatest([
      this.checkOutForm.valueChanges,
      this.store.select(CheckOutSelectors.getShippingBusinessPartner),
    ]).subscribe(([, bp]) => {
      const request = {
        desired_delivery: new Date(
          this.checkOutForm.controls['deliveryDate']?.value,
        ),
        shipping_sap_code:
          this.checkOutForm.controls['shippingConditions']?.value,
        customer_order_number: this.checkOutForm.controls['orderNumber']?.value,
        comment: this.checkOutForm.controls['comment']?.value,
        is_complete_dispatch:
          this.checkOutForm.controls['completeDelivery']?.value,
        consignee_number: this.checkOutForm.controls['goodsRecipient']?.value,
        is_weekly_order: this.checkOutForm.controls['weeklyOrder']?.value,
        includes_tmf_items:
          this.checkOutForm.controls['includesTmfItems']?.value,
        ship_from_wch: this.checkOutForm.controls['shipFromWch']?.value,
        shipCollect: this.checkOutForm.controls['shipCollect']?.value,
        account_number:
          this.checkOutForm.controls['shipCollectAccountNumber']?.value,
        order_type: this.checkOutForm.controls['orderType']?.value,
        shipping_city: bp?.city,
        shipping_company: bp?.company_name,
        shipping_country: bp?.country_code,
        shipping_house_number: bp?.house_number,
        shipping_street: bp?.street_name,
      } as OrderRequest;

      this.store.dispatch(
        CheckOutActions.SetOrderRequest({
          payload: {
            request: request,
            orderFormValid: this.checkOutForm.valid,
          },
        }),
      );
    });

    this.isGermany$ = this.userService.currentUser.pipe(
      map(
        (userContext) =>
          userContext?.company_sapid === this.palf_germany_sap_user ||
          userContext?.ga_sapid === this.palf_germany_sap_user,
      ),
    );

    this.weeklyOrderVisible$ = combineLatest([
      this.store.select(CheckOutSelectors.getCartTotal),
      this.isGermany$,
    ]).pipe(
      map(([total, isGermany]) => {
        if (total && total >= 1600 && isGermany) {
          return true;
        }
        return false;
      }),
    );

    this.isNorthAmerica$ = this.userService.currentUser.pipe(
      map(
        (user) =>
          user?.area_sapid_nr === 'P7102' ||
          user?.area_sapid_nr === '0000007200' ||
          user?.area_sapid_nr === '0000007100' ||
          user?.area_sapid_nr === '0000007800' ||
          user?.area_sapid_nr === 'P7101' ||
          user?.area_sapid_nr === '0000007300',
      ),
      tap((isNorthAmerica) => {
        if (isNorthAmerica) {
          this.checkOutForm.controls['orderType'].setValidators([
            Validators.required,
          ]);
          this.checkOutForm.updateValueAndValidity();
        }
      }),
    );
  }

  setShippingBusinessPartner(businessPartner: BusinessPartnerResponse) {
    this.store.dispatch(
      CheckOutActions.SetShippingBusinessPartner({
        payload: { bp: businessPartner },
      }),
    );
  }

  openSelectShippingDialog() {
    const config = new MatDialogConfig();
    config.viewContainerRef = this._viewContainerRef;
    config.width = new DsSpacingPipe().transform(300);
    const dialogRef = this._dialog.open(CustomersComponent, config);
    dialogRef.componentInstance.allowCreateCustomer = false;
    dialogRef.componentInstance.includeOnlyAccountingGroups = [
      SAP_PARTNER_ROLES.Debitors,
      SAP_PARTNER_ROLES.Consignee,
      SAP_PARTNER_ROLES.PalfingerGroup,
    ];
    dialogRef.componentInstance.searchInBpmFirst = false;
    dialogRef.componentInstance.selected
      .pipe(takeUntil(dialogRef.afterClosed()))
      .subscribe((data) => {
        this.store.dispatch(
          CheckOutActions.SetShippingBusinessPartner({
            payload: { bp: data },
          }),
        );
        dialogRef.close();
      });
  }
}
