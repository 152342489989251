import {
  AfterViewInit,
  Component,
  ComponentRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IndustrySelectionComponent } from '../industry-selection/industry-selection.component';
import { CustomerActions } from '../shared';

@Component({
  selector: 'paldesk-industry',
  templateUrl: './industry.component.html',
  styleUrls: ['./industry.component.scss'],
  standalone: false,
})
export class IndustryComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input()
  touched: boolean;
  @Input()
  set industries(value: string[]) {
    this._industries = value;
  }
  get industries(): string[] {
    return this.industrySelectionComponentRefArray.map(
      (item) => item.instance.marketControl.value,
    );
  }

  get valid(): boolean {
    return this.industrySelectionComponentRefArray.every(
      (item) => item.instance.marketControl.valid,
    );
  }

  @ViewChild('industryHost', {
    read: ViewContainerRef,
    static: true,
  })
  industryHost: ViewContainerRef;

  private _industries: string[];
  private destroy$ = new Subject<void>();

  private industrySelectionComponentRefArray: Array<
    ComponentRef<IndustrySelectionComponent>
  >;

  constructor(private store: Store<any>) {}

  ngAfterViewInit() {
    this.industrySelectionComponentRefArray = new Array<
      ComponentRef<IndustrySelectionComponent>
    >();

    this._industries.forEach((industry: string, index: number) => {
      if (industry || index === 0) {
        this.addIndustry(industry);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const touchedChange = changes['touched'];
    if (touchedChange && touchedChange.currentValue) {
      this.industrySelectionComponentRefArray.forEach((ref) => {
        ref.instance.markAsTouched();
      });
    }
  }

  addIndustry(industry: string) {
    const item = this.industryHost.createComponent(IndustrySelectionComponent);
    item.instance.setIndustry(industry);
    this.industrySelectionComponentRefArray.push(item);
    this.setIndustryInputs();

    if (this.touched) {
      item.instance.markAsTouched();
    }

    item.instance.add
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.addIndustry(''));

    item.instance.delete.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.industrySelectionComponentRefArray.length > 1) {
        this.removeIndustry(item);
      } else {
        item.instance.setIndustry('');
      }
    });

    item.instance.marketControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() =>
        this.store.dispatch(
          CustomerActions.UpdateIndustries({ payload: this.industries }),
        ),
      );

    this.store.dispatch(
      CustomerActions.UpdateIndustries({ payload: this.industries }),
    );
  }

  removeIndustry(item: ComponentRef<IndustrySelectionComponent>) {
    this.industrySelectionComponentRefArray.splice(
      this.industrySelectionComponentRefArray.indexOf(item),
      1,
    );
    item.destroy();
    this.setIndustryInputs();
    this.store.dispatch(
      CustomerActions.UpdateIndustries({ payload: this.industries }),
    );
  }

  setIndustryInputs(): void {
    const maxIndustriesCount = 5;
    const industriesCount = this.industrySelectionComponentRefArray.length;
    const hasOnlyOneIndustry =
      this.industrySelectionComponentRefArray.length === 1;
    this.industrySelectionComponentRefArray
      .map((item) => item.instance)
      .forEach((industry, index) => {
        industry.addable =
          index + 1 === industriesCount && industriesCount < maxIndustriesCount;
        industry.isTheOnlyRow = hasOnlyOneIndustry;
      });
  }
}
