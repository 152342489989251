import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SparepartsShopState } from '../../store';
import { CheckOutActions } from '../../store/checkout';

@Component({
  selector: 'sp-shop-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss'],
  standalone: false,
})
export class CheckOutPageComponent implements OnInit {
  shoppingCartPanelOpenState = true;
  checkoutPanelOpenState = false;

  constructor(private store: Store<SparepartsShopState>) {}

  ngOnInit() {
    this.store.dispatch(CheckOutActions.LoadShippingConditions());
  }
}
