import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Bom } from '@paldesk/shared-lib/data-access/sparepart-shop-generated';
import { Observable } from 'rxjs';
import { SparepartTableEntry } from '../../../shared/models/sparepart-table-entry';
import { SparepartsShopState } from '../../../store';
import { SearchActions, SearchSelectors } from '../../../store/search';

const columns = ['material_number', 'description', 'amount', 'price'];

@Component({
  selector: 'sp-shop-bill-of-material',
  templateUrl: './bill-of-material.component.html',
  styleUrls: ['./bill-of-material.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BillOfMaterialComponent implements OnInit {
  bomContainerLoading$: Observable<boolean>;
  bomContainerLoadingError$: Observable<boolean>;
  displayedColumns: Array<string>;
  materialNumber: string;
  dataSource: MatTableDataSource<Bom> | null;

  @Input() set sparepart(entry: SparepartTableEntry) {
    if (entry.material_number) {
      this.materialNumber = entry.material_number;
      this.store.dispatch(
        SearchActions.LoadBom({
          payload: { material_number: entry.material_number },
        }),
      );
    }
  }

  constructor(
    private store: Store<SparepartsShopState>,
    private dialogRef: MatDialogRef<BillOfMaterialComponent>,
  ) {
    this.store.select(SearchSelectors.getBOM).subscribe((bomContainer) => {
      if (bomContainer?.bill_of_materials) {
        this.dataSource = new MatTableDataSource(
          bomContainer.bill_of_materials,
        );
      } else this.dataSource = null;
    });
    this.bomContainerLoading$ = this.store.select(
      SearchSelectors.getBOMLoading,
    );
    this.bomContainerLoadingError$ = this.store.select(
      SearchSelectors.getBOMLoadingError,
    );
  }

  ngOnInit() {
    this.displayedColumns = columns;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
