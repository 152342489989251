import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BusinessPartnerResponse } from '@data-access/bpm-generated';

@Component({
  selector: 'paldesk-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss'],
  standalone: false,
})
export class CustomerListComponent {
  @Input()
  loading: boolean;
  @Input()
  searchError: boolean;
  @Input()
  customers: BusinessPartnerResponse[];
  @Input() customersCount: number;
  @Input() disableSelectionForCompanyExistingInBPM = false;
  @Input() selectedUser: boolean;
  @Output()
  showCustomer: EventEmitter<BusinessPartnerResponse> =
    new EventEmitter<BusinessPartnerResponse>();
  @Output()
  selectCustomer: EventEmitter<BusinessPartnerResponse> =
    new EventEmitter<BusinessPartnerResponse>();

  handleCustomer(customer: BusinessPartnerResponse) {
    this.selectCustomer.emit(customer);
  }
}
